a {
  text-decoration: none;
}

.homeSlider {
  margin: 20px;
  background: #f2f8ff;
}

.homeSlider .homeSliderImg {
  width: 100%;
}

.homeSlider .homeSliderImg img {
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.fildfghjk {
  display: block;
}

.textslider {
  margin-top: 30px;
  white-space: nowrap;
  animation: floatText 5s infinite alternate ease-in-out;
  /* color: #000; */
  color: var(--clr-theme-1);
  font-weight: 600;
  font-size: 18px;
  font-family: playfair display, serif;
}

/* ========================FirstNav Start===================== */
.mobileNav {
  position: absolute;
  top: 80px;
  right: 0;
  background: #fff;
  width: 100%;
  z-index: 99;
  font-weight: 700;
}
.mobileNav .Firstnav {
  margin-left: 150px;
}
.mobileNav .Firstnav li {
  text-align: left;
}
.mobileNav .Firstnav li ul {
  margin: 0;
  padding: 0;
}
.mobileNav .Firstnav li .link {
  color: #000;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 16px;
  text-align: left;
}
/* ========================FirstNav End===================== */
/* ========================SecondNav Start===================== */
.mobileNav1 {
  position: absolute;
  top: 80px;
  right: 0;
  background: #fff;
  width: 80%;
  z-index: 99;
}
.Secondnav {
  margin-left: 50px;
}
.mobileNav1 .Secondnav li {
  text-align: left;
}
.mobileNav1 .Secondnav li ul {
  margin: 0;
  padding: 0;
}
.mobileNav1 .Secondnav li .link {
  color: #000;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 16px;
  text-align: left;
}

.logo-img {
  max-height: 85px;
}

.card {
  margin: 10px;
  border-color: #da2c46;
}

.srevice-img {
  display: grid;
  justify-content: center;
}

.bd-team__content {
  height: 175px;
}

.cource-list li {
  list-style: disc;
}

.wp-icon {
  position: fixed;
  bottom: 15px;
  left: 15px;
  cursor: pointer;
  z-index: 99;
}

.wp-icon img {
  width: 45px;
  height: 45px;
  /* border-radius: 50px; */
}

.wrapper .button {
  display: inline-block;
  height: 60px;
  width: 20px;
  margin: 0, 5px;
  background-color: #da2c46;
  cursor: pointer;
  overflow: hidden;
  border-radius: 50px;
  transition: all 0.3s ease-out;
}

.wrapper .button:hover {
  width: 150px;
  height: 60px;
}

.wrapper .button .icon {
  display: inline-block;
  height: 60px;
  width: 60px;
  border-radius: 50px;
  box-sizing: border-box;
  line-height: 60px;
}

.wrapper .button .icon i {
  font-size: 20px;
  font-weight: 600;
  line-height: 60px;
  margin-left: 10px;
  color: #fff;
}

/* ========================FirstNav End===================== */
/* ========================certificate Start===================== */
.certificate {
  display: flex;
  justify-content: center;
  align-items: center;
}
.outer-border {
  width: 800px;
  height: 630px;
  padding: 20px;
  text-align: center;
  border: 10px solid #da2c46;
}

.inner-dotted-border {
  width: 750px;
  height: 570px;
  padding: 20px;
  text-align: center;
  border: 5px solid #da2c46;
  border-style: dotted;
}
.CertifiNumber {
  display: flex;
  justify-content: end;
  align-items: center;
}
.certification {
  font-size: 50px;
  font-weight: bold;
  /* color: #663ab7; */
  color: #da2c46;
}

.certify {
  font-size: 25px;
}
.certifyfname {
  font-size: 20px !important;
}
.name {
  font-size: 30px;
  color: #000;
  font-weight: bold;
}

.fs-30 {
  font-size: 30px;
}

.fs-20 {
  font-size: 20px;
}
/* ========================certificate End===================== */

.manuone {
  display: none;
}
.footLogo {
  display: none;
}

@media screen and (max-width: 600px) {
  .footLogo {
    display: block;
    width: 50%;
    margin-bottom: 4%;
  }
  .menu {
    display: block;
    width: 80%;
    background: #fff;
    position: absolute;
    top: 70px;
    right: 0;
  }
  .menu li {
    text-align: left;
    margin-left: 10%;
    color: #000;
  }
  .menu li ul {
    margin: 0;
    padding: 0;
  }
  .menu li ul .link {
    color: #000;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
      sans-serif;
    font-size: 16px;
    text-align: left;
  }

  .manuone {
    display: block;
    width: 80%;
    background: #fff;
    position: absolute;
    top: 70px;
    right: 0;
  }

  .manuone li {
    text-align: left;
    margin-left: 10%;
    color: #000;
  }

  /* ========================certificate Start===================== */
  .certificate {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .outer-border {
    width: 800px;
    height: 530px;
    padding: 20px;
    text-align: center;
    border: 10px solid #da2c46;
  }

  .inner-dotted-border {
    width: 450px;
    height: 470px;
    padding: 20px;
    text-align: center;
    border: 5px solid #da2c46;
    border-style: dotted;
  }
  .CertifiNumber {
    display: flex;
    justify-content: end;
    align-items: center;
  }
  .certification {
    font-size: 30px;
    font-weight: bold;
    /* color: #663ab7; */
    color: #da2c46;
  }

  .certify {
    font-size: 20px;
  }
  .certifyfname {
    font-size: 20px !important;
  }
  .name {
    font-size: 30px;
    color: #000;
    font-weight: 500;
  }

  .fs-30 {
    font-size: 20px;
    font-weight: 500;
  }

  .fs-20 {
    font-size: 20px;
  }
  /* ========================certificate End===================== */
}

@media screen and (max-width: 390px) {
  /* ========================certificate Start===================== */
  .certificate {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .outer-border {
    width: 800px;
    height: 500px;
    padding: 20px;
    text-align: center;
    border: 10px solid #da2c46;
  }

  .inner-dotted-border {
    width: 315px;
    height: 430px;
    padding: 20px;
    text-align: center;
    border: 5px solid #da2c46;
    border-style: dotted;
  }
  .CertifiNumber {
    display: flex;
    justify-content: end;
    align-items: center;
  }
  .certification {
    font-size: 20px;
    font-weight: bold;
    /* color: #663ab7; */
    color: #da2c46;
  }

  .certify {
    font-size: 18px;
  }
  .certifyfname {
    font-size: 18px !important;
  }
  .name {
    font-size: 26px;
    color: #000;
    font-weight: 500;
  }

  .fs-30 {
    font-size: 18px;
    font-weight: 500;
  }

  .fs-20 {
    font-size: 18px;
  }
  /* ========================certificate End===================== */
}

@media screen and (max-width: 380px) {
  /* ========================certificate Start===================== */
  .certificate {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .outer-border {
    width: 800px;
    height: 440px;
    padding: 20px;
    text-align: center;
    border: 10px solid #da2c46;
  }

  .inner-dotted-border {
    width: 280px;
    height: 380px;
    padding: 20px;
    text-align: center;
    border: 5px solid #da2c46;
    border-style: dotted;
  }
  .CertifiNumber {
    display: flex;
    justify-content: end;
    align-items: center;
  }
  .CertifiNumber1 {
    font-size: 12px;
  }
  .certification {
    font-size: 18px;
    font-weight: bold;
    /* color: #663ab7; */
    color: #da2c46;
  }

  .certify {
    font-size: 15px;
  }
  .certifyfname {
    font-size: 15px !important;
  }
  .name {
    font-size: 20px;
    color: #000;
    font-weight: 500;
  }

  .fs-30 {
    font-size: 15px;
    font-weight: 500;
  }

  .fs-20 {
    font-size: 16px;
  }
  /* ========================certificate End===================== */
}

/* ==========================Read Our Policies============================ */
